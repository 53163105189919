import { useState, useEffect } from "react"

/**
 * Custom React hook to track the width and height of the window.
 * This will allow us to do more regarding responsiveness in our design.
 */
let defaultHeight: number
let defaultWidth: number

if (typeof window !== `undefined`) {
  defaultHeight = window.innerHeight
  defaultWidth = window.innerWidth
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: defaultWidth,
    height: defaultHeight,
  })

  useEffect(() => {
    /**
     * Event handler triggered by a window resizing
     */
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener("resize", handleResize)
    // Call event handler immediately so that we an initialize the state values.
    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}

export default useWindowSize
