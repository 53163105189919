import styled from "styled-components"

export type customHeading = {
  mobileLineHeight?: number
  desktopLineHeight?: number
  mobileFontAlign?:
    | "left"
    | "center"
    | "right"
    | "left !important"
    | "center !important"
    | "right !important"
  desktopFontAlign?:
    | "left"
    | "center"
    | "right"
    | "left !important"
    | "center !important"
    | "right !important"
  mobileFontSize?: string
  desktopFontSize?: string
  fontColor?: "#FCFBFB" | "#020202" | "#3F8070"
  mobileMargin?: string
  desktopMargin?: string
  fontFamily?: "Grafier" | "Florensans"
  padded?: boolean | string
  maxWidth?: string
}

type heroContainer = {
  height?: number | string
  backgroundImage?: string | HTMLImageElement
  isVideo?: boolean
  is404?: boolean
}

type videoContainer = {
  width: number
}

type heroWrapper = {
  isCarousel: boolean
}

export const HeroWrapper = styled.div<heroWrapper>`
  margin-bottom: -5px;

  .slick-slider {
    .slick-dots {
      display: ${({ isCarousel }) => (isCarousel ? "block" : "none")};
    }
  }
`

export const HeroContainer = styled.div<heroContainer>`
  position: relative;
  z-index: 1000;
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: unset;
  align-items: center;
  align-content: center;
  height: ${({ height, is404 }) =>
    is404
      ? "100vh !important"
      : typeof height === "string"
      ? height
      : `${height}px`};
  padding: 0px 24px;
  background: ${({ backgroundImage, isVideo }) =>
    isVideo ? `` : `url(${backgroundImage}) rgba(2, 2, 2, 0.5) no-repeat`};
  background-size: cover;
  background-blend-mode: multiply;
  background-position: center;

  @media screen and (min-width: 960px) {
    height: ${({ is404 }) => (is404 ? "100vh" : "808px")};
  }

  .btn-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    > button {
      margin-bottom: 45px;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }
    @media only screen and (min-width: 960px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      > button {
        margin: 60px;
        &:last-of-type {
          margin-bottom: 60px;
        }
      }
    }
  }

  > .carousel-logo {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`
export const CarouselIndicatorContainer = styled.div`
  padding: 5px;
  position: absolute;
  bottom: 35px;
`

export const CarouselIndicator = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: ${({
    theme: {
      main: { offWhite },
    },
  }) => offWhite};
`
export const LogoList = styled.div`
  padding: 0 0 32px 0;
  display: flex;
  height: 300px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  > img {
    max-width: 100%;
    padding: 8px 0;
  }
  @media only screen and (min-width: 960px) {
    flex-flow: row wrap;
    max-width: 1350px;
    width: 85%;
    > img,
    a {
      padding: 0 32px;
    }
    > img {
      height: 50px;
    }
    > a > img {
      height: 40px;
    }
  }
`
export const Heading = styled.h1<customHeading>`
  margin: ${({ mobileMargin }) => (mobileMargin ? mobileMargin : "unset")};
  width: 100%;
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : "")}
  ${({ mobileLineHeight }) =>
    mobileLineHeight ? `line-height: ${mobileLineHeight}px;` : ""}
  ${({ mobileFontSize }) =>
    mobileFontSize ? `font-size: ${mobileFontSize};` : ""}
  ${({ mobileFontAlign }) =>
    mobileFontAlign ? `text-align: ${mobileFontAlign};` : ""}
  ${({ fontColor }) => (fontColor ? `color: ${fontColor};` : "")}
  ${({ fontFamily }) => (fontFamily ? `font-family: ${fontFamily};` : "")}
  ${({ padded }) =>
    padded === true
      ? "padding-top: calc(50% - 55px);"
      : typeof padded === "string"
      ? `padding: ${padded};`
      : ``}

  @media only screen and (min-width: 960px) {
    margin: ${({ desktopMargin }) => (desktopMargin ? desktopMargin : "unset")};
    ${({ desktopFontSize }) =>
      desktopFontSize ? `font-size: ${desktopFontSize};` : ""}
    ${({ desktopLineHeight }) =>
      desktopLineHeight ? `line-height: ${desktopLineHeight}px;` : ""}
    ${({ desktopFontAlign }) =>
      desktopFontAlign ? `text-align: ${desktopFontAlign};` : ""}
  }
`

export const Subheading = styled.h2<customHeading>`
  margin: ${({ mobileMargin }) => (mobileMargin ? mobileMargin : "unset")};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : "")}
  ${({ mobileLineHeight }) =>
    mobileLineHeight ? `line-height: ${mobileLineHeight}px;` : ""}
  ${({ mobileFontSize }) =>
    mobileFontSize ? `font-size: ${mobileFontSize};` : ""}
  ${({ mobileFontAlign }) =>
    mobileFontAlign ? `text-align: ${mobileFontAlign};` : ""}
  ${({ fontColor }) => (fontColor ? `color: ${fontColor};` : "")}
  ${({ fontFamily }) => (fontFamily ? `font-family: ${fontFamily};` : "")}
  ${({ padded }) =>
    padded === true
      ? "padding-top: calc(50% - 55px);"
      : typeof padded === "string"
      ? `padding: ${padded} !important;`
      : `padding-top: unset !important;`}

  @media only screen and (min-width: 960px) {
    ${({ desktopFontSize }) =>
      desktopFontSize ? `font-size: ${desktopFontSize};` : ""}
    padding: 0;
    margin: ${({ desktopMargin }) => (desktopMargin ? desktopMargin : "unset")};
    ${({ desktopLineHeight }) =>
      desktopLineHeight ? `line-height: ${desktopLineHeight}px;` : ""}
    margin-left: auto;
    margin-right: auto;
    ${({ desktopFontAlign }) =>
      desktopFontAlign ? `text-align: ${desktopFontAlign};` : ""}
  }
`
export const VideoContainer = styled.div<videoContainer>`
  .video-bg {
    /* width: ${({ width }) => width}px !important; */
    transition: all 0.3s ease;

    > div:last-of-type > div:last-of-type {
      height: 125%;
      margin-top: -7%;
    }
  }
`
