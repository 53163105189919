import styled from "styled-components"

type customButton = {
  mobileMargin?: string
  desktopMargin?: string
  width?: string
  maxWidth?: string
  textDecoration?: boolean
  mobileFontSize?: string
  desktopFontSize?: string
  isWhite?: boolean
}

export const Button = styled.button<customButton>`
  transition: all 0.3s ease;
  ${({ mobileMargin }) => (mobileMargin ? `margin: ${mobileMargin};` : ``)}
  ${({ width }) => (width ? `width: ${width};` : "width: 212px;")}
  ${({ maxWidth }) =>
    maxWidth ? `max-width: ${maxWidth};` : "max-width: unset;"}

  font-family: "GrafierBold";
  background: transparent;
  display: block;
  border: 2px solid
    ${({
      theme: {
        main: { tanghoGreen },
      },
    }) => tanghoGreen};
  color: ${({
    theme: {
      main: { tanghoGreen },
    },
  }) => tanghoGreen};
  padding: 12px 8px 12px 8px;
  text-align: center;
  font-size: 18px;

  &:disabled {
    border-color: #bebebe;
    color: #bebebe;
    cursor: not-allowed;
    &:hover {
      color: #bebebe;
      border-color: #bebebe;
      background-color: transparent;
    }
  }

  &:hover {
    color: ${({
      theme: {
        main: { offWhite },
      },
    }) => offWhite};
    border: 2px solid
      ${({
        theme: {
          main: { offWhite, tanghoGreen },
        },
        isWhite,
      }) => (isWhite ? tanghoGreen : offWhite)};
    ${({
      theme: {
        main: { offWhite, tanghoGreen },
      },
      isWhite,
    }) => (isWhite ? `background: ${tanghoGreen};` : ``)}
  }

  &:active {
    background: ${({
      theme: {
        main: { tanghoGreen },
      },
    }) => tanghoGreen};
    border: 2px solid
      ${({
        theme: {
          main: { tanghoGreen },
        },
      }) => tanghoGreen};
    color: ${({
      theme: {
        main: { offWhite },
      },
    }) => offWhite};
  }

  @media only screen and (min-width: 960px) {
    width: 288px;
    font-size: 24px;
    ${({ desktopMargin }) => (desktopMargin ? `margin: ${desktopMargin};` : ``)}

    &.contact-send-btn {
      margin-right: 0;
      margin-left: auto;
    }
  }
`

export const Hyperlink = styled.a<customButton>`
  border: none;
  text-decoration: none;
  transition: all 0.3s ease;
  ${({ mobileMargin }) => (mobileMargin ? `margin: ${mobileMargin};` : ``)}
  ${({ width }) => (width ? `width: ${width};` : "width: 212px;")}
  ${({ maxWidth }) =>
    maxWidth ? `max-width: ${maxWidth};` : "max-width: unset;"}
  background: transparent;
  display: block;
  font-size: ${({ mobileFontSize }) =>
    mobileFontSize ? `${mobileFontSize}` : "18px"};
  ${({ textDecoration }) => (textDecoration ? `border-bottom: 2px solid;` : "")}
  color: ${({
    theme: {
      main: { tanghoGreen },
    },
  }) => tanghoGreen};
  padding-bottom: ${({ textDecoration }) => (textDecoration ? "2px" : "")};

  @media screen and (min-width: 960px) {
    font-size: ${({ desktopFontSize }) =>
      desktopFontSize ? `${desktopFontSize}` : "24px"};
    ${({ desktopMargin }) => (desktopMargin ? `margin: ${desktopMargin};` : ``)}
  }
`
