import React, { ImgHTMLAttributes, useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import Slider, { Settings } from "react-slick"
import YoutubeBackground from "../../YoutubeBackground"
import {
  HeroContainer,
  HeroWrapper,
  Heading,
  Subheading,
  customHeading,
  LogoList,
  VideoContainer,
} from "./style"
import Button from "../../Button"
import { Logo } from "../../Logo/style"
// tslint:disable-next-line: no-submodule-imports
import "slick-carousel/slick/slick.css"
// tslint:disable-next-line: no-submodule-imports
import "slick-carousel/slick/slick-theme.css"
import useWindowSize from "../../../utils/useWindowSize"

/**
 * Interface declaration for a carousel item.
 * @property backgroundImg Background image to be displayed in carousel slide.
 * @property btnList Array of objects containing data for slide buttons.
 * @property imgList Array of objects containing a height, href and a url/file path for logos to be displayed in slide.
 * @property heading A slide title text.
 * @property subheading A slide subtitle text.
 */
export interface CarouselItem {
  backgroundImg: string
  btnList?: TanghoButton[]
  imgList?: imgItem[]
  subheading?: Text
  heading?: Text
  isVideo?: boolean
}

export interface Text extends customHeading {
  text: string
}

export interface TanghoButton {
  label: string
  isLink?: boolean
  onClick?: any
  href?: string
  textDecoration?: boolean
  width?: string
  maxWidth?: string
  mobileMargin?: string
  desktopMargin?: string
  mobileFontSize?: string
  desktopFontSize?: string
}

export type imgItem = {
  height: number
  url: string
  href?: string
}

type Props = {
  carouselItems: CarouselItem[]
  mobileHeight: number | string
  subSlider?: any
  setMainSlider?: any
  is404?: boolean
}

const index = (props: Props) => {
  const [initWidth, setInitWidth] = useState(
    typeof window !== "undefined" ? window.outerWidth : 0
  )
  const { width } = useWindowSize()
  const [overlay, setOverlay] = useState("rgba(2,2,2,.8)")

  if (typeof window !== "undefined") {
    useEffect(() => {
      if (initWidth !== width) {
        setInitWidth(width)
        // window.location.reload()
      }
    }, [width])
  }

  /**
   * Settings for configuring the slick carousel.
   */
  const settings: Settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: process.env.GATSBY_CAROUSEL_AUTOPLAY_SPEED
      ? Number(process.env.GATSBY_CAROUSEL_AUTOPLAY_SPEED)
      : 7000,
    centerPadding: "0",
    centerMode: true,
    useTransform: false,
    useCSS: false,
  }

  const { carouselItems, mobileHeight, subSlider, setMainSlider, is404 } = props

  /**
   * Generates a button to be placed above background image.
   * @param button Button properties for generating the appropriate element.
   * @returns A button with the provided text.
   */
  const generateButton = (button: TanghoButton) => {
    return <Button {...button}>{button.label}</Button>
  }

  /**
   * Generates a logo to be placed above background image.
   * @param h a height for the logo to be displayed.
   * @param url url/file path to image for logo.
   * @returns A logo with a specified height.
   */
  const generateLogo = (h: number, url: string, href: string | undefined) => {
    return typeof href === "string" ? (
      <a href={href} target="_blank" rel="">
        <Logo className="carousel-logo" height={h} src={url} />
      </a>
    ) : (
      <Logo className="carousel-logo" height={h} src={url} />
    )
  }

  const generateSlide = (carouselItem: CarouselItem) => {
    const { isVideo } = carouselItem
    return isVideo ? (
      <VideoContainer width={width}>
        <YoutubeBackground
          aspectRatio="16:9"
          onPlay={() => setOverlay("rgba(2,2,2,0.5)")}
          overlay={overlay}
          className="video-bg"
          videoId={carouselItem.backgroundImg}
        >
          {getSlideContent(carouselItem)}
        </YoutubeBackground>
      </VideoContainer>
    ) : (
      getSlideContent(carouselItem)
    )
  }

  const getSlideContent = (carouselItem: CarouselItem) => {
    const { isVideo } = carouselItem
    return (
      <HeroContainer
        backgroundImage={carouselItem.backgroundImg}
        height={mobileHeight}
        isVideo={isVideo}
        is404={is404}
      >
        {carouselItem.heading && (
          <Heading {...carouselItem.heading}>
            {carouselItem.heading.text}
          </Heading>
        )}
        {carouselItem.subheading && (
          <Subheading {...carouselItem.subheading}>
            {carouselItem.subheading.text}
          </Subheading>
        )}
        {carouselItem.imgList ? (
          <LogoList>
            {carouselItem.imgList.map((img: imgItem) =>
              generateLogo(img.height, img.url, img.href ? img.href : undefined)
            )}
          </LogoList>
        ) : null}
        {carouselItem.btnList ? (
          <div className="btn-wrapper">
            {carouselItem.btnList.map((btn: TanghoButton) =>
              generateButton(btn)
            )}
          </div>
        ) : null}
      </HeroContainer>
    )
  }

  // PSEUDOCODE
  return (
    <HeroWrapper isCarousel={carouselItems && carouselItems.length > 1}>
      <Slider
        {...settings}
        ref={(slider) => (setMainSlider ? setMainSlider(slider) : null)}
        asNavFor={subSlider}
      >
        {carouselItems.map((carouselItem: CarouselItem) =>
          generateSlide(carouselItem)
        )}
      </Slider>
    </HeroWrapper>
  )
}

export default index
