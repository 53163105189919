import React from "react"
import { Button, Hyperlink } from "./style"

type Props = {
  className?: string
  label: string
  isLink?: boolean
  onClick?: any
  href?: string
  textDecoration?: boolean
  width?: string
  maxWidth?: string
  mobileMargin?: string
  desktopMargin?: string
  mobileFontSize?: string
  desktopFontSize?: string
}

const index = (props: Props) => {
  const {
    onClick,
    className,
    label,
    isLink,
    href,
    width,
    maxWidth,
    mobileFontSize,
    desktopFontSize,
    mobileMargin,
    desktopMargin,
    textDecoration,
  } = props
  return isLink ? (
    <Hyperlink
      className={className ? className : ""}
      href={href ? href : ""}
      target={href ? "_blank" : ""}
      width={width}
      maxWidth={maxWidth}
      mobileFontSize={mobileFontSize}
      desktopFontSize={desktopFontSize}
      mobileMargin={mobileMargin}
      desktopMargin={desktopMargin}
      textDecoration={textDecoration}
    >
      {label}
    </Hyperlink>
  ) : (
    <Button
      className={className ? className : ""}
      onClick={onClick}
      width={width}
      maxWidth={maxWidth}
      mobileFontSize={mobileFontSize}
      desktopFontSize={desktopFontSize}
      mobileMargin={mobileMargin}
      desktopMargin={desktopMargin}
    >
      {label}
    </Button>
  )
}

export default index
